/*@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700;900&display=swap');*/
@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@400;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 18px;
}

body {
    @apply
    bg-white
    text-slate-900
    overflow-x-hidden;
}

/* links */

a {
    @apply
    bg-purple-100
    transition
    ;

    &:hover,
    &:focus,
    &:active {
        @apply
        bg-purple-200
        ;
    }

    &.darker {
        @apply
        bg-purple-200
        ;

        &:hover,
        &:focus,
        &:active {
            @apply
            bg-purple-300
            ;
        }
    }

    &.inverted {
        @apply
        bg-purple-900
        ;

        &:hover,
        &:focus,
        &:active {
            @apply
            bg-purple-800
            ;
        }
    }
}

/* section */

section {
    h1 {
        @apply
        text-6xl sm:text-7xl font-black leading-none;
    }

    h2 {
        @apply
        text-3xl font-black tracking-wide leading-none;
    }

    h3 {
        @apply
        text-lg font-bold tracking-wide leading-none;
    }

    strong {
        @apply
        text-slate-800
        font-bold tracking-wide;
    }

    ul {
        @apply
        space-y-1
        ;

        li {
            @apply relative;

            &:before {
                content: "—";
                @apply
                absolute -left-5
                text-stone-400;
            }
        }
    }

    code {
        @apply
        px-2 py-0.5
        bg-stone-100
        text-stone-800
        text-sm font-mono
        ;
    }
}

.section-content {
    @apply
    px-4 py-16 sm:py-24
    space-y-16
    ;
}

/* layout */

.container-full-width {
    @apply container mx-auto;
}

.container-2-cols {
    @apply grid lg:grid-cols-2 gap-8;
}

.container-3-cols {
    @apply grid lg:grid-cols-3 gap-8;
}

.container-4-cols {
    @apply grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8;
}

/* buttons */

.button {
    @apply
    px-12 py-4
    border-4 border-slate-900
    rounded-full
    text-center
    leading-none
    uppercase
    font-bold
    tracking-wider
    z-10
    ;
}

.primary-button {
    @apply
    bg-purple-900
    text-white
    ;

    &:hover,
    &:focus,
    &:active {
        @apply
        bg-purple-800
        ;
    }
}

.secondary-button {
    @apply
    bg-white
    ;

    &:hover,
    &:focus,
    &:active {
        @apply
        bg-purple-100
        ;
    }
}

/**/

.divider {
    @apply
    h-[2px]
    bg-slate-900
    ;
}

/**/

nav {
}

/* cards */

.cards-container {
    @apply
    max-w-sm sm:max-w-4xl
    mx-auto
    grid sm:grid-cols-2
    gap-4 sm:gap-8
    ;
}

.card-border {
    @apply
    border-2 border-slate-900
    rounded-lg
    ;
}

.card {
    @apply
    flex flex-col items-start
    gap-4
    p-6 sm:p-8
    z-10
    ;

    i {
        @apply
        w-12 h-12
        flex justify-center items-center
        bg-white
        text-3xl
        border-2
        rounded;
    }
}

/* tags */

.tags-container {
    @apply
    overflow-x-clip
    flex
    justify-center
    gap-4 sm:gap-8
    ;
    mask: linear-gradient(to right, transparent 0%, black 20%, black 80%, transparent 100%);

    .tag {
        @apply
        shrink-0
        px-4 py-1
        border border-slate-900
        rounded
        bg-white
        text-slate-900
        text-sm uppercase tracking-wide leading-none;
    }
}

/* features */

#features {
    .feature-selector {
        @apply
        flex items-center gap-4
        overflow-x-scroll
        ;

        label {
            @apply
            shrink-0
            px-6 py-2
            bg-white
            leading-none
            uppercase text-sm tracking-wide
            border-2 border-slate-900 border-dashed
            rounded-full
            cursor-pointer
            ;

            &:hover,
            &:focus,
            &:active {
                @apply
                bg-purple-100
                ;
            }
        }

        input[type="radio"] {
            @apply
            hidden
            ;

            &:checked + label {
                @apply
                bg-purple-900
                text-white
                border-solid
                ;

                &:hover,
                &:focus,
                &:active {
                    @apply
                    bg-purple-800
                    ;
                }
            }
        }
    }

    .feature {
        /*
        @apply
        ;
        */

        .feature-content {
            @apply
            max-w-sm sm:max-w-4xl mx-auto
            grid sm:grid-cols-2 items-stretch gap-4 sm:gap-8
            ;

            .feature-description {
                @apply
                space-y-4 sm:space-y-8
                ;
            }

            p {
                @apply
                p-8
                space-y-4
                bg-violet-100;
            }

            ul {
                @apply
                p-8
                space-y-4
                bg-pink-100;
            }

            video {
                @apply
                object-center
                bg-white
                border-2 border-slate-900
                rounded-lg
                ;
            }

            p, ul, video {
                @apply
                border-2 border-slate-900
                rounded-lg
                relative z-10
                ;
            }
        }
    }
}

/* download */

#download {
    .download-button {
        @apply
        p-4
        flex items-center
        gap-4
        bg-purple-200
        text-stone-900
        border-4 border-white
        rounded-full
        ;

        &:hover,
        &:focus,
        &:active {
            @apply
            bg-purple-800
            ;

            h4, h5 {
                @apply
                text-white
                ;
            }
        }

        h4 {
            @apply
            leading-none
            text-xl font-bold
            ;
        }

        h5 {
            @apply
            leading-none
            text-xs uppercase
            ;
        }
    }

    .download-button-icon {
        @apply
        w-12 h-12
        flex justify-center items-center
        bg-white
        rounded-full
        ;

        i {
            @apply
            text-3xl
            ;
        }
    }
}

/* footer */

footer {
    h1 {
        @apply
        font-bold;
    }

    /*
    @apply
    max-w-sm md:max-w-3xl
    mx-auto
    px-4
    ;

    .contact-container {
        @apply
        p-6 md:p-8
        flex
        flex-col md:flex-row
        gap-6 md:gap-8
        justify-center
        items-center
        text-center
        bg-slate-100
        rounded-lg
        ;

        p {
            @apply
            text-lg
            ;
        }

        a {
            @apply
            px-5 py-1
            flex justify-center items-center
            bg-blue-100
            text-blue-900
            font-bold uppercase
            border-4
            border-white
            rounded
            ;
        }
    }
    */
}
